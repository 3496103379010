<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 09:37:26
 * @Description: 
-->
<template>
	<div>
		<div class="home">
			<Head></Head>
			<div class="content">
				<div class="wrapper">
					<!-- <div class="swipe">
						<el-carousel height="350px">
							<el-carousel-item v-for="item in swipe" :key="item.id">
								<img style="width: 100%;" :src="item.url" alt="">
							</el-carousel-item>
						</el-carousel>
					</div> -->
					<div class="product_title">关于我们</div>
					<div class="plates">
						<div class="pdd-content">
							<div class="content">
								<div class="sub-title"></div>
								<p>福清市音西云探电子商务服务部。一家集品牌供应链，网红孵化和抖音直播、短视频运营培训教学为一体的新媒体公司，致力于孵化网红达人，优质短视频制作，更是品牌运营的策划师，核心团队具有丰富的互联网运营，短视频，直播经验，公司当前已储备大v网红，各级别主播，拥有上万人同城探店达人矩阵团，致力于为商家商家提供多元化的产品销售手段，精准化品牌宣传。</p>
								
							</div>
						</div>
					</div>
					<div class="footer">
						<Foot></Foot>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {
		get
	} from "../utils/request";
	import Head from './components/Head.vue'
	import Foot from './components/Foot.vue'
	export default {
		// 使用注册组件
		components: {
			// 1.引入所需组件

			// 2.注册组件
			Head,
			Foot
		},
		data() {
			return {
				// message: "hello",
				swipe: [{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202307101021007d3305321.png",
					},
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202307101021007d3305321.png",
					}
				],

			};
		},
		methods: {},
		created() {},
	};
</script>

<style lang="scss" scoped>
	.content {
		.wrapper {

			// width: 100%;
			.plates {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.plate {
					display: flex;
					width: 25%;
					padding: 10px;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 30px;

					.plate_img {
						display: block;
						width: 100%;
						height: 100%;
						border-top-left-radius: 20px;
						border-top-right-radius: 20px;
					}

					.plate_title {
						margin-top: 15px;
					}
				}
			}
		}

		.product_title {
			font-size: 30px;
			font-weight: 600;
			margin: 20px;
		}
	}

	.footer {
		height: 200px;
		// background-color: #528c38;
	}
</style>